//require('./bootstrap');
require('bootstrap');
Swal = require('sweetalert2/dist/sweetalert2.all.js');
/**
 * Biblioteki ---------------------------------------------
 */
global.moment = require('moment');
//require('./libs/font/font-2f9e125919');
//require('@fortawesome/fontawesome-free/js/all.min');
require('./libs/magnific/jquery.magnific-popup.min');
require('./components/cookieinfo');
require('admin-lte/plugins/inputmask/jquery.inputmask');

/**
 * Komponenty ---------------------------------------------
 */
// require('./components/carouselAnimation');
// require('./components/navbarScroll');
require('./components/main');
require('owl.carousel/dist/owl.carousel.min');
require('./components/daterangepicker');
require('./components/modal/product');
require('./components/product/addProduct');
require('./components/product/changeProduct');
require('./components/product/condition');
require('./components/product/supplyArea');
require('./components/shopping/steps');
require('./components/messages/messages');

// require('./components/forms/contactForm');
// require('./components/forms/newsletterForm');
