$(document).ready(function () {

    $("#supply_area_modal").on("click", "#supplyAreaModalJS", function () {
        supplyAreaModal();
    });

});

function supplyAreaModal() {
    Swal.fire({
        position: 'center',
        type: 'info',
        title: '<div class="text-info">Sprawdź obszar dostawy:</div>',
        html: getHTML(),
        showConfirmButton: true,
        scrollbarPadding: false
        //timer: 3000
    });
}

window.searchSupplyJS = function() {

    let url = '/info/supply-area';
    let code = $('#postalCodeJS').val();
    $.ajax(
        {
            method: "POST",
            url: url,
            data: {_token: config._token, code: code},
            dataType: "json",
            cache: false,
        })
        .done(function (result) {
            let resposneData = result;
            if (resposneData.status.code === 1) {

                if(resposneData.data.supply){
                    $('#postalCodeResposne').html('<p class="text-success">Twój obszar jest objęty dostawą, zapraszamy do zakupów.</p>');
                } else {
                    $('#postalCodeResposne').html('<p class="text-danger">Niestety Twój obszar jest poza strefą dostawy lub został wprowadzony niepoprawny kod pocztowy.</p>');
                }

            }
        })
        .fail(function (result) {
            $('#postalCodeResposne').text('test');
            let errorText = '';
            let resposneData = result.responseJSON;
            if (resposneData.status.code === -10) {
                $.each(resposneData.data.errors, function (key, value) {
                    errorText += '<p>' + value[0] + '</p>';
                });

                Swal.fire({
                    position: 'center',
                    type: 'error',
                    title: '<div class="text-danger">' + resposneData.status.message + '</div>',
                    html: errorText,
                    showConfirmButton: true,
                    scrollbarPadding: false
                });
            } else {
                Swal.fire({
                    position: 'center',
                    type: 'error',
                    title: '<div class="text-danger">' + resposneData.status.message + '</div>',
                    html: errorText,
                    showConfirmButton: true,
                    scrollbarPadding: false
                    //timer: 3000
                });
            }
        })
        .always(function () {
        });
};

function getHTML() {
    return '<div id="saerchSupply">' +
        '<input type="text" id="postalCodeJS" placeholder="Kod pocztowy" title="Kod pocztowy" data-search />' +
        '<br/><button onclick="searchSupplyJS()" class="btn btn-success mt-3">Sprawdź</button>' +
        '<div class="row my-3 text-center">' +
        '<div class="col-8 offset-2" id="postalCodeResposne"></div>' +
        '</div>' +
        '</div>';
}