$(document).ready(function () {
	min = 0; // Minimum of 0
	max = 99; // Maximum of 10

	$(".spinner").on("click", ".minus", function () {
		let thisObject = $(this).parent().parent();
		let value = thisObject.find(".count").val();

		thisObject.find(".count").val(parseInt(value) - 1);
	});

	$(".spinner").on("click", ".plus", function () {
		let thisObject = $(this).parent().parent();
		let value = thisObject.find(".count").val();

		thisObject.find(".count").val(parseInt(value) + 1);
	});

	$(".product-content").on("click", "#addProductJS", function () {
		addProductData($(this));
	}).on('submit', '#jsProductForm', function (e) {
		e.preventDefault();
		addProductData($(this));
		return false;
	});

	$("#form_product").on("click", "#addFormProductJS", function () {
		addProductData($(this));
	});

	$(".add-order-product").on("click", function () {
		let button = $(this);
		let cart = $('.cart');

		if(button.data('product_price')){
			Swal.fire({
				title: button.data('product_name'),
				html: 'Cena produktu uległa zmianie.<br><br>' +
					'<b>Stara cena:</b> ' + button.data('old_product_price') + 'zł<br>' +
					'<b>Nowa cena:</b> ' + button.data('product_price') + 'zł',
				icon: 'warning',
				showCancelButton: true,
				cancelButtonColor: '#6e7d88',
				confirmButtonColor: '#3ab54a',
				confirmButtonText: 'Mimo to, dodaj to koszyka',
				cancelButtonText: 'Anuluj',
				reverseButtons: true
			}).then((result) => {
				if (result.isConfirmed) {
					button.addClass('sendtocart');
					setTimeout(function(){
						button.removeClass('sendtocart');
						cart.addClass('shake');
						setTimeout(function(){
							cart.removeClass('shake');
						},500)
					},1000)
					addOrderProductData($(this));
				}
			})
		} else {
			button.addClass('sendtocart');
			setTimeout(function(){
				button.removeClass('sendtocart');
				cart.addClass('shake');
				setTimeout(function(){
					cart.removeClass('shake');
				},500)
			},1000)
			addOrderProductData($(this));
		}

	});

});

window.searchSupplyJS = function () {

	var searchVal = $('#searchSupplyJS').val();
	var filterItems = $('[data-filter-item]');

	if (searchVal.length > 2) {
		filterItems.addClass('hidden');

		$('[data-filter-item][data-filter-name*="' + searchVal.toLowerCase() + '"]').removeClass('hidden');
	} else {
		filterItems.removeClass('hidden');
	}
};

let delay;

function addProductData(source) {
	if (source.closest('form')) {
		clearTimeout(delay);

		delay = setTimeout(function () {
			let form = source.closest('form');
			let url = form.attr("action");

			let token = form.find("input[name=_token]").val();
			let quantity = parseInt(form.find("input[name=quantity]").val());
			let productId = form.find("input[name=product_id]").val();

			$(".myAlert-top").show();
			setTimeout(function () {
				$(".myAlert-top").hide();
			}, 1500);

			$.ajax(
				{
					method: "POST",
					url: url,
					data: {_token: token, product_id: productId, quantity: quantity},
					dataType: "json",
					cache: false,
				})
				.done(function (result) {
					let resposneData = result;
					if (resposneData.status.code === 1) {
						form.find("input[name=quantity]").val(1);
						$('#jsTotalValue').text(resposneData.data.total_value + ' zł');
						$('.shoppingCardCount').text(resposneData.data.card_count);

					}
				})
				.fail(function (result) {
					let errorText = '';
					let resposneData = result.responseJSON;
					if (resposneData.status.code === -10) {
						$.each(resposneData.data.errors, function (key, value) {
							errorText += '<p>' + value[0] + '</p>';
						});

						Swal.fire({
							position: 'center',
							type: 'error',
							title: '<div class="text-danger">' + resposneData.status.message + '</div>',
							html: errorText,
							showConfirmButton: true,
							scrollbarPadding: false
						});
					} else {
						Swal.fire({
							position: 'center',
							type: 'error',
							title: '<div class="text-danger">' + resposneData.status.message + '</div>',
							html: '<p>' + resposneData.data.message + '</p>',
							showConfirmButton: true,
							scrollbarPadding: false
							//timer: 3000
						});
					}
				})
				.always(function () {
				});
		}, 1000);
	};
}

function addOrderProductData(source) {
	clearTimeout(delay);
	let url = source.data('url');
	let productId = source.data('product_id');
	let quantity = source.data('quantity');
	let token = source.data('token');
	delay = setTimeout(function () {
		$(".myAlert-top").show();
		setTimeout(function () {
			$(".myAlert-top").hide();
		}, 1500);

		$.ajax(
			{
				method: "POST",
				url: url,
				data: {_token: token, product_id: productId, quantity: quantity},
				dataType: "json",
				cache: false,
			})
			.done(function (result) {
				let resposneData = result;
				if (resposneData.status.code === 1) {
					$('#jsTotalValue').text(resposneData.data.total_value + ' zł');
					$('.shoppingCardCount').text(resposneData.data.card_count);

				}
			})
			.fail(function (result) {
				let errorText = '';
				let resposneData = result.responseJSON;
				if (resposneData.status.code === -10) {
					$.each(resposneData.data.errors, function (key, value) {
						errorText += '<p>' + value[0] + '</p>';
					});

					Swal.fire({
						position: 'center',
						type: 'error',
						title: '<div class="text-danger">' + resposneData.status.message + '</div>',
						html: errorText,
						showConfirmButton: true,
						scrollbarPadding: false
					});
				} else {
					Swal.fire({
						position: 'center',
						type: 'error',
						title: '<div class="text-danger">' + resposneData.status.message + '</div>',
						html: '<p>' + resposneData.data.message + '</p>',
						showConfirmButton: true,
						scrollbarPadding: false
						//timer: 3000
					});
				}
			})
			.always(function () {
			});
	}, 1000);
}

function getSupplyData() {
	return '<div id="saerchSupply">' +
		'<input type="text" id="searchSupplyJS" onkeyup="searchSupplyJS()" placeholder="Szukaj po nazwie" title="Szukaj po nazwie" data-search />' +
		'<div class="row mt-3">' +
		'<div onclick="Swal.close();" class="col-lg-4 ool-sm-6 p-2" data-filter-item data-filter-name="boniowice"><a href="#">Boniowice</a></div>' +
		'<div onclick="Swal.close();" class="col-lg-4 ool-sm-6 p-2" data-filter-item data-filter-name="bytom"><a href="#">Bytom</a></div>' +
		'<div onclick="Swal.close();" class="col-lg-4 ool-sm-6 p-2" data-filter-item data-filter-name="czekanów"><a href="#">Czekanów</a></div>' +
		'<div onclick="Swal.close();" class="col-lg-4 ool-sm-6 p-2" data-filter-item data-filter-name="gierałtowice"><a href="#">Gierałtowice</a></div>' +
		'<div onclick="Swal.close();" class="col-lg-4 ool-sm-6 p-2" data-filter-item data-filter-name="gliwice"><a href="#">Gliwice</a></div>' +
		'<div onclick="Swal.close();" class="col-lg-4 ool-sm-6 p-2" data-filter-item data-filter-name="kamieniec"><a href="#">Kamieniec</a></div>' +
		'<div onclick="Swal.close();" class="col-lg-4 ool-sm-6 p-2" data-filter-item data-filter-name="karchowice"><a href="#">Karchowice</a></div>' +
		'<div onclick="Swal.close();" class="col-lg-4 ool-sm-6 p-2" data-filter-item data-filter-name="kleszczów"><a href="#">Kleszczów</a></div>' +
		'<div onclick="Swal.close();" class="col-lg-4 ool-sm-6 p-2" data-filter-item data-filter-name="knurów"><a href="#">Knurów</a></div>' +
		'<div onclick="Swal.close();" class="col-lg-4 ool-sm-6 p-2" data-filter-item data-filter-name="kozłów"><a href="#">Kozłów</a></div>' +
		'<div onclick="Swal.close();" class="col-lg-4 ool-sm-6 p-2" data-filter-item data-filter-name="kuźniczka"><a href="#">Kuźniczka</a></div>' +
		'<div onclick="Swal.close();" class="col-lg-4 ool-sm-6 p-2" data-filter-item data-filter-name="leboszowice"><a href="#">Leboszowice</a></div>' +
		'<div onclick="Swal.close();" class="col-lg-4 ool-sm-6 p-2" data-filter-item data-filter-name="ligota łabędzka"><a href="#">Ligota Łabędzka</a></div>' +
		'<div onclick="Swal.close();" class="col-lg-4 ool-sm-6 p-2" data-filter-item data-filter-name="łany wielkie"><a href="#">Łany Wielkie</a></div>' +
		'<div onclick="Swal.close();" class="col-lg-4 ool-sm-6 p-2" data-filter-item data-filter-name="nieborowice"><a href="#">Nieborowice</a></div>' +
		'<div onclick="Swal.close();" class="col-lg-4 ool-sm-6 p-2" data-filter-item data-filter-name="paniówki"><a href="#">Paniówki</a></div>' +
		'<div onclick="Swal.close();" class="col-lg-4 ool-sm-6 p-2" data-filter-item data-filter-name="pilchowice"><a href="#">Pilchowice</a></div>' +
		'<div onclick="Swal.close();" class="col-lg-4 ool-sm-6 p-2" data-filter-item data-filter-name="przezchlebie"><a href="#">Przezchlebie</a></div>' +
		'<div onclick="Swal.close();" class="col-lg-4 ool-sm-6 p-2" data-filter-item data-filter-name="przyszowice"><a href="#">Przyszowice</a></div>' +
		'<div onclick="Swal.close();" class="col-lg-4 ool-sm-6 p-2" data-filter-item data-filter-name="pyskowice"><a href="#">Pyskowice</a></div>' +
		'<div onclick="Swal.close();" class="col-lg-4 ool-sm-6 p-2" data-filter-item data-filter-name="smolnica"><a href="#">Smolnica</a></div>' +
		'<div onclick="Swal.close();" class="col-lg-4 ool-sm-6 p-2" data-filter-item data-filter-name="sośnicowice"><a href="#">Sośnicowice</a></div>' +
		'<div onclick="Swal.close();" class="col-lg-4 ool-sm-6 p-2" data-filter-item data-filter-name="świętoszowice"><a href="#">Świętoszowice</a></div>' +
		'<div onclick="Swal.close();" class="col-lg-4 ool-sm-6 p-2" data-filter-item data-filter-name="trachy"><a href="#">Trachy</a></div>' +
		'<div onclick="Swal.close();" class="col-lg-4 ool-sm-6 p-2" data-filter-item data-filter-name="wieszowa"><a href="#">Wieszowa</a></div>' +
		'<div onclick="Swal.close();" class="col-lg-4 ool-sm-6 p-2" data-filter-item data-filter-name="zabrze"><a href="#">Zabrze</a></div>' +
		'<div onclick="Swal.close();" class="col-lg-4 ool-sm-6 p-2" data-filter-item data-filter-name="zbrosławice"><a href="#">Zbrosławice</a></div>' +
		'<div onclick="Swal.close();" class="col-lg-4 ool-sm-6 p-2" data-filter-item data-filter-name="ziemięcice"><a href="#">Ziemięcice</a></div>' +
		'<div onclick="Swal.close();" class="col-lg-4 ool-sm-6 p-2" data-filter-item data-filter-name="żernica"><a href="#">Żernica</a></div>' +
		'</div></div>';
}


function supplyAreaModal() {
	Swal.fire({
		position: 'center',
		type: 'info',
		title: '<div class="text-info">Sprawdź obszar dostawy:</div>',
		html: getHTML(),
		showConfirmButton: true,
		scrollbarPadding: false
		//timer: 3000
	});
}

window.searchSupplyJS = function () {

	let url = '/info/supply-area';
	let code = $('#postalCodeJS').val();
	$.ajax(
		{
			method: "POST",
			url: url,
			data: {_token: config._token, code: code},
			dataType: "json",
			cache: false,
		})
		.done(function (result) {
			let resposneData = result;
			if (resposneData.status.code === 1) {

				if (resposneData.data.supply) {
					$('#postalCodeResposne').html('<p class="text-success">Twój obszar jest objęty dostawą, zapraszamy do zakupów.</p>');
				} else {
					$('#postalCodeResposne').html('<p class="text-danger">Niestety Twój obszar jest poza strefą dostawy lub został wprowadzony niepoprawny kod pocztowy.</p>');
				}

			}
		})
		.fail(function (result) {
			$('#postalCodeResposne').text('test');
			let errorText = '';
			let resposneData = result.responseJSON;
			if (resposneData.status.code === -10) {
				$.each(resposneData.data.errors, function (key, value) {
					errorText += '<p>' + value[0] + '</p>';
				});

				Swal.fire({
					position: 'center',
					type: 'error',
					title: '<div class="text-danger">' + resposneData.status.message + '</div>',
					html: errorText,
					showConfirmButton: true,
					scrollbarPadding: false
				});
			} else {
				Swal.fire({
					position: 'center',
					type: 'error',
					title: '<div class="text-danger">' + resposneData.status.message + '</div>',
					html: errorText,
					showConfirmButton: true,
					scrollbarPadding: false
					//timer: 3000
				});
			}
		})
		.always(function () {
		});
};

function getHTML() {
	return '<div id="saerchSupply">' +
		'<input type="text" id="postalCodeJS" placeholder="Kod pocztowy" title="Kod pocztowy" data-search />' +
		'<br/><button onclick="searchSupplyJS()" class="btn btn-success mt-3">Sprawdź</button>' +
		'<div class="row my-3 text-center">' +
		'<div class="col-8 offset-2" id="postalCodeResposne"></div>' +
		'</div>' +
		'</div>';
}
