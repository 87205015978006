$(document).ready(function () {

    $("#condition").on("click", "#conditionJS", function () {
        if ($(this).is(":checked"))
        {
            condition(1);
        } else {
            condition(0);
        }

    });

});

function condition(value) {

        let token = $('#conditionTokenJS').val();
        let url = '/product/condition';

            $.ajax(
                {
                    method: "POST",
                    url: url,
                    data: {_token: token, senior_supply: value},
                    dataType: "json",
                    cache: false,
                })
                .done(function (result) {
                    let resposneData = result;
                    if (resposneData.status.code === 1) {
                        $('#shoppingCardTotalAmount').text(resposneData.data.get_total_amount + ' zł');
                        $('#shoppingSupplyValue').text(resposneData.data.supply_value + ' zł');

                        if(resposneData.data.senior_supply){
                            Swal.fire({
                                position: 'center',
                                type: 'success',
                                title: '<div class="text-success">' + resposneData.data.message + '</div>',
                                showConfirmButton: true,
                                scrollbarPadding: false
                                //timer: 3000
                            });
                        }

                    }
                })
                .fail(function (result) {
                    let errorText = '';
                    let resposneData = result.responseJSON;
                    if (resposneData.status.code === -10) {
                        $.each(resposneData.data.errors, function (key, value) {
                            errorText += '<p>' + value[0] + '</p>';
                        });

                        Swal.fire({
                            position: 'center',
                            type: 'error',
                            title: '<div class="text-danger">' + resposneData.status.message + '</div>',
                            html: errorText,
                            showConfirmButton: true,
                            scrollbarPadding: false
                        });
                    } else {
                        Swal.fire({
                            position: 'center',
                            type: 'error',
                            title: '<div class="text-danger">' + resposneData.status.message + '</div>',
                            html: errorText,
                            showConfirmButton: true,
                            scrollbarPadding: false
                            //timer: 3000
                        });
                    }
                })
                .always(function () {
                });
}