$(document).ready(function () {
    $(".spinner").on("click", "#changedButtonJS", function () {
        changeProductData($(this));
    });

    $(".spinner").on("change", "#changedInputJS", function () {
        changeProductData($(this));
    });
});

let delay;

function changeProductData(source) {
    if (source.closest('form')) {
        clearTimeout(delay);
        let thisProduct = source;

        delay = setTimeout(function () {
            let url = thisProduct.closest('form').attr("action");
            let formData = thisProduct.closest('form').serialize();
            let productRow = thisProduct.closest('#jsProductRow');

            $.ajax(
                {
                    method: "POST",
                    url: url,
                    data: formData,
                    dataType: "json",
                    cache: false,
                })
                .done(function (result) {
                    let resposneData = result;
                    if (resposneData.status.code === 1) {
                        productRow.find('#ProductPrice').text(resposneData.data.product_price + ' zł');
                        productRow.find('#ProductPriceSum').text(resposneData.data.product_price_sum + ' zł');
                        $('#shoppingCardTotal').text(resposneData.data.get_total + ' zł');
                        $('#shoppingCardTotalAmount').text(resposneData.data.get_total_amount + ' zł');
                        $('#shoppingSupplyValue').text(resposneData.data.supply_value + ' zł');
                    }
                })
                .fail(function (result) {
                    let errorText = '';
                    let resposneData = result.responseJSON;
                    if (resposneData.status.code === -10) {
                        $.each(resposneData.data.errors, function (key, value) {
                            errorText += '<p>' + value[0] + '</p>';
                        });

                        Swal.fire({
                            position: 'center',
                            type: 'error',
                            title: '<div class="text-danger">' + resposneData.status.message + '</div>',
                            html: errorText,
                            showConfirmButton: true,
                            scrollbarPadding: false
                        });
                    } else {
                        Swal.fire({
                            position: 'center',
                            type: 'error',
                            title: '<div class="text-danger">' + resposneData.status.message + '</div>',
                            html: '<p>' + resposneData.data.message + '</p>',
                            showConfirmButton: true,
                            scrollbarPadding: false
                            //timer: 3000
                        });
                    }
                })
                .always(function () {
                });

        }, 1500);
    };
}
