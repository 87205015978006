$(document).ready(function () {

    $('#productModal').on('show.bs.modal', function (event) {
        $('#productGallery').trigger('destroy.owl.carousel');
        let targetData = $(event.relatedTarget);
        let name = targetData.data('name');
        let id = targetData.data('id');
        let description = targetData.data('description');
        let details_description = targetData.data('details_description');
        let brutto_value = targetData.data('brutto_value');
        let amount = targetData.data('amount');
        let unit = targetData.data('unit');
        let gallery = targetData.data('gallery');
        let unit_amount_modal = targetData.data('unit_amount_modal');


        let modal = $(this);
        modal.find('#productGallery').html('');
        $.each(gallery, function(index, value) {
            let source = '<div class="slide-nowe">' +
                '<a href="#">' +
                '<img class="pic-1 ofert-img" src="' + value.thumb + '">' +
                '</a>' +
                '</div>';

            modal.find('#productGallery').append(source);
        });

        $("#productGallery").owlCarousel({
            lazyLoad:true,
            items: 1,
            itemsDesktop: [1199, 3],
            itemsDesktopSmall: [980, 2],
            itemsMobile: [600, 1],
            loop:true,
            margin:10,
            dots:true,
            nav:false,
            autoplay:true,
            smartSpeed: 3000,
            autoplayTimeout:7000,

        });

        modal.find("input[name=product_id]").val(id);
        modal.find('#detailsData').html(details_description);
        modal.find('#productModalTitle').html(name);
        modal.find('#productTitle').html(name);
        modal.find('#productAmount').html(amount);
        if(unit_amount_modal){
            modal.find('#productUnit').html('/' + unit_amount_modal + unit);
        } else {
            modal.find('#productUnit').html('/' + unit);
        }
        modal.find('#productUnitAva').html(unit);
        modal.find('#productBruttoValue').html(brutto_value);
        modal.find('#productDescription').html(description);
        modal.find('#detailsData').html(details_description);




    })

});