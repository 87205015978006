$(document).ready(function () {

	$("#productGallery").owlCarousel({
		lazyLoad: true,
		items: 1,
		itemsDesktop: [1199, 3],
		itemsDesktopSmall: [980, 2],
		itemsMobile: [600, 1],
		loop: true,
		margin: 10,
		dots: true,
		nav: false,
		autoplay: true,
		smartSpeed: 3000,
		autoplayTimeout: 7000,

	});
});

var btn = $('#scroll-top');

$(window).scroll(function () {
	if ($(window).scrollTop() > 200) {
		btn.addClass('show');
	} else {
		btn.removeClass('show');
	}
});

btn.on('click', function (e) {
	e.preventDefault();
	$('html, body').animate({
		scrollTop: 0
	}, '300');
});

$('[data-mask]').inputmask();
// Input product end

$('.carousel').carousel({
	interval: 5000,
	pause: false
});


function myAlertTop() {
	$(".myAlert-top").show();
	setTimeout(function () {
		$(".myAlert-top").hide();
	}, 1500);
}

function myAlertBottom() {
	$(".myAlert-bottom").show();
	setTimeout(function () {
		$(".myAlert-bottom").hide();
	}, 2000);
}


$(function () {
	$('[data-toggle="tooltip"]').tooltip()
})


$(document).ready(function () {
	$('.addtocart').on('click', function () {

		var button = $(this);
		var cart = $('.cart');
		var cartTotal = cart.attr('data-totalitems');


		button.addClass('sendtocart');
		setTimeout(function () {
			button.removeClass('sendtocart');
			cart.addClass('shake');
			setTimeout(function () {
				cart.removeClass('shake');
			}, 500)
		}, 1000)
	})
})


$(document).ready(function () {
	$('body').scrollspy({
		target: ".scrollspy",
		offset: 50
	});
});

$("#scrooll a").on('click', function (event) {
	event.preventDefault();
	var hash = this.hash;
	$('html, body').animate({
		scrollTop: $(hash).offset().top - 0
	}, 1800, function () {
		window.location.hash = hash;
	});
});

$('.dropdown-menu a.dropdown-toggle').on('click', function (e) {
	if (!$(this).next().hasClass('show')) {
		$(this).parents('.dropdown-menu').first().find('.show').removeClass("show");
	}
	var $subMenu = $(this).next(".dropdown-menu");
	$subMenu.toggleClass('show');

	$(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function (e) {
		$('.dropdown-submenu .show').removeClass("show");
	});

	return false;
});

function toggleNameColor() {
	const item = document.querySelector("#fixcolor")
	if(item) {
		item.style.color = item.style.color === 'red' ? '#053d5e' : 'red';
	}
}

setInterval(toggleNameColor, 1500)
