$(document).ready(function() {

    function alertMessageJs(type, message){
        if (type === 'error') {
            Swal.fire({
                position: 'center',
                type: 'error',
                title: '<div class="text-danger">Błąd!</div>',
                html: message,
                showConfirmButton: true,
                scrollbarPadding: false
            });
        }

        if (type === 'success') {
            Swal.fire({
                position: 'center',
                type: 'success',
                title: '<div class="text-success">Sukces!</div>',
                html: message,
                showConfirmButton: true,
                scrollbarPadding: false
            });
        }
    }

    let divObj = $('#alertMessageJs');

    if(divObj.length){
        alertMessageJs(divObj.data('type'), divObj.text());
    }

});